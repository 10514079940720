import React from "react";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import { Container } from "react-bootstrap";

function Home() {
  return (
    <div>
      <Header />
      <div className="home-page mt-5">
        <Container>
          <div className="text-start">
            <p className="fs-5 text-muted">
              Bahá’í Beatz is a unique platform dedicated to uplifting and
              inspiring hearts through music infused with Bahá’í themes and
              teachings. It serves as a hub for discovering and sharing
              spiritually enriching songs from artists around the world,
              blending diverse musical styles with messages of unity, love,
              and service. Whether you're looking for devotional music,
              vibrant rhythms, or reflective melodies, Bahá’í Beatz provides a
              space where sound and spirit come together.
              <br /> <br />
              Download Bahá’í Beatz below and immerse yourself in music that
              nourishes the soul.
            </p>
          </div>

          {/* Centered & Responsive App Download Buttons */}
          <div className="d-flex flex-wrap justify-content-center gap-3 mt-4 mb-5">
            <div className="col-lg-6 col-sm-12 text-center">
              <a
                href="https://play.google.com/store/apps/details?id=com.bahai.beatz&hl=en&gl=US"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/googleplay.png`}
                  alt="Google Play"
                  height="80"
                />
              </a>
            </div>
            <div className="col-lg-6 col-sm-12 text-center">
              <a
                href="https://apps.apple.com/us/app/bahai-beatz/id6460647259"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/appstore.png`}
                  alt="App Store"
                  height="80"
                />
              </a>
            </div>
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
}

export default Home;

import React, { useState } from "react";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import qs from "qs";
import moment from "moment";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import MessageBox from "../component/MessageBox";
import { Modal, Button, Table } from "react-bootstrap";
import { dateTimeFormat, dateFormat } from "../common/function";

const materialLoad = -1;

function OutageReport() {
  const [message, setMessage] = useState(null);
  const [list, setList] = useState([]);
  const [appCodes, setAppCodes] = useState([]);
  const [loading, setLoading] = useState(null);
  const [fromDate, setFromDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10));
  const [showModal, setShowModal] = useState(false);
  const [detailList, setDetailList] = useState([]);

  const isLogin = secureLocalStorage.getItem("isLogin");
  const isAdmin = secureLocalStorage.getItem("isAdmin");
  const token = secureLocalStorage.getItem("token");
  const handleSearch = () => {
    if (!!isLogin && !!isAdmin) {
      setLoading(materialLoad);
      const data = qs.stringify({
        date_from: fromDate,
        date_to: toDate,
      });

      axios
        .post(
          `${process.env.REACT_APP_MONITORING_URL}/get_outage_summary`,
          data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              JWTToken: token,
            },
          }
        )
        .then((response) => {
          setLoading(null);
          const responseData = response.data.data;
          const _appCodes = Array.from(
            new Set(
              responseData.reduce((acc, item) => {
                // Directly push the appCode from each item
                if (item.appCode) {
                  acc.push(item.appCode);
                }
                return acc;
              }, [])
            )
          );
          setAppCodes(_appCodes);

          // Group the data by date and store it in state
          const groupedData = responseData.reduce((acc, item) => {
            // Create a key for each unique date
            const dateKey =
              dateFormat(item.date) || `Week ${dateFormat(item.minDate)}`;

            // Group items by date
            if (!acc[dateKey]) {
              acc[dateKey] = [];
            }
            acc[dateKey].push(item);

            return acc;
          }, {});

          // Set the list with grouped data
          setList(
            Object.entries(groupedData).map(([dateKey, items]) => ({
              dateKey,
              items,
            }))
          );
        })
        .catch((error) => {
          setLoading(null);
          setMessage({ type: "error", message: error.message });
        });
    }
  };

  const handleShowDetails = (appId, fromDate, toDate) => {
    setShowModal(true);
    const data = qs.stringify({
      date_from: moment(fromDate).utc().format("YYYY-MM-DD"), // Ensure UTC format
      date_to: moment(toDate).utc().format("YYYY-MM-DD"), // Ensure UTC format
      app_id: appId,
    });

    axios
      .post(`${process.env.REACT_APP_MONITORING_URL}/get_outage_list`, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          JWTToken: token,
        },
      })
      .then((response) => {
        setDetailList(response.data.data);
      })
      .catch((error) => {
        setMessage({ type: "error", message: error.message });
      });
  };

  if (!isAdmin || !isLogin)
    return <MessageBox type="error" message="You do not have permission" />;

  return (
    <>
      <Header />
      <div className="fluid-container m-3">
        <h3>Outage Summary Report</h3>
        <div className="row mb-3">
          <div className="col-md-4">
            <label htmlFor="fromDate">Date From:</label>
            <input
              type="date"
              id="fromDate"
              className="form-control"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="toDate">Date To:</label>
            <input
              type="date"
              id="toDate"
              className="form-control"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </div>
          <div className="col-md-4 d-flex align-items-end">
            <button className="btn btn-primary" onClick={handleSearch}>
              {loading === materialLoad ? <div className="loader" /> : "Search"}
            </button>
          </div>
        </div>

        {message && message.type && (
          <MessageBox type={message.type} message={message.message} />
        )}

        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Date</th>
                {appCodes.length > 0 &&
                  appCodes.map((appCode) => <th key={appCode}>{appCode}</th>)}
              </tr>
            </thead>
            <tbody>
              {list.map(({ dateKey, items }) => (
                <tr key={dateKey}>
                  <td>{dateKey}</td>
                  {appCodes.map((appCode) => {
                    // Safeguard to ensure `items` is not undefined
                    const app = items?.find((item) => item.appCode === appCode); // Check if items exist
                    return (
                      <td key={appCode}>
                        <span
                          className="btn btn-link p-0"
                          onClick={() =>
                            handleShowDetails(
                              app?.appType,
                              app?.date || app?.minDate,
                              app?.date || app?.maxDate
                            )
                          }
                        >
                          {app ? app.totalDuration : 0}{" "}
                          {/* Using totalDuration if matched */}
                        </span>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Outage Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Date Time</th>
                  <th>Duration</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>
                {detailList.map((item, index) => (
                  <tr key={index}>
                    <td>{dateTimeFormat(item.time)}</td>
                    <td>{item.duration}</td>
                    <td>{item.message}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Footer />
    </>
  );
}

export default OutageReport;

import moment from "moment";

export const formatSize = (size) => {
  if (size >= 1024 * 1024) {
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  } else if (size >= 1024) {
    return `${(size / 1024).toFixed(2)} KB`;
  } else {
    return `${size} Bytes`;
  }
};

export const inputDateFormat = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

export const dateTimeFormat = (date) => {
  const Edana7 = "D MMM YYYY, h:mm a";
  return date ? moment(date.replace('Z','')).local().format(Edana7) : date;
};

export const dateFormat = (date) => {
  const formattedDate = date ? date.split("T")[0] : ""; // Ensure date is not undefined or null
  const Edana7 = "D MMM YYYY";
  return formattedDate ? moment(formattedDate).format(Edana7) : formattedDate; // Return formatted date or raw date if no date
};

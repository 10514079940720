import React, { useCallback, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import OTPInput from "../component/OTPInput";
import MessageBox from "../component/MessageBox";

function Login() {
  const [step, setStep] = useState(1);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activationCode, setActivationCode] = useState("");
  const [email, setEmail] = useState("");
  const [userID, setUserID] = useState("");
  const navigate = useNavigate();


  const handleLogin = async () => {
    try {
      // Step 1: Login API
      setLoading(true);
      const loginResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/login_user`,
        {
          DeviceToken: process.env.REACT_APP_DEVICE_TOKEN,
          Email: email,
          AppName: process.env.REACT_APP_NAME,
          Device: "Web",
        }
      );
      setLoading(false);
      setMessage({
        type: "success",
        message: `OTP sent on your email ${email}`,
      });
      if (loginResponse.data.success) {
        const userID = loginResponse.data.data.UserID;
        setUserID(userID);
        setStep(2);
      } else {
        setMessage({ type: "error", message: loginResponse.data.message });
      }
    } catch (error) {
      setLoading(false);
      setMessage({ type: "error", message: error.message });
    }
  };

  const handleVerifyOTP = async () => {
    // Step 2: Activate App API
    setLoading(true);
    const result = await axios.post(
      `${process.env.REACT_APP_API_URL}/activate_app`,
      {
        UserID: userID,
        ActivationCode: activationCode,
        Device: "Web",
        guest_user_id: "",
        language_id: 32,
        application: 0,
        DeviceToken: process.env.REACT_APP_DEVICE_TOKEN,
        AppName: process.env.REACT_APP_NAME,
      }
    );

    if (result.data.success) {
      const token = result.data.data.JWTToken;
      setMessage({
        type: "success",
        message: "OTP verified successfully, wait fetching data!",
      });
      // Step 3: Get User Profile API
      const profileResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/profile`,
        {
          headers: {
            JWTToken: token,
          },
        }
      );
      setLoading(false);
      if (profileResponse.data.success) {
        const userProfile = profileResponse.data.data[0];
        const { USER_ID, NAME, IMAGE_URL, IS_ADMIN } = userProfile;
        // Save to localStorage
        secureLocalStorage.setItem("token", token);
        secureLocalStorage.setItem("user_id", USER_ID);
        secureLocalStorage.setItem("name", NAME);
        secureLocalStorage.setItem("picture", IMAGE_URL);
        secureLocalStorage.setItem("isAdmin", IS_ADMIN);
        secureLocalStorage.setItem("isLogin", true);
        navigate("/");
      }
    } else {
      setLoading(false);
      setMessage({ type: "error", message: result.data.message });
    }
  };

  const onChangeOTP = useCallback((otp) => {
    setActivationCode(otp.join("").trim());
  },[]);

  return (
    <div>
      <Header />
      <div
        className="bg-image loginPage"
        style={{
          backgroundImage:
            "url('https://emsglobal.ed-space.net/KeyDoc/Attachments/Comms/516/pearl_email_activation_back.png')",
          backgroundSize: "cover",
        }}
      >
        <div className="container h-100 d-flex justify-content-center align-items-center">
          <div className="card" style={{ width: "400px", opacity: 0.8 }}>
            <div className="card-body">
              <h5 className="card-title">
                <i className="fa fa-lock-open"></i> Authentication
              </h5>

              {message && message.type && (
                <MessageBox type={message.type} message={message.message} />
              )}

              <form>
                {step === 1 && (
                  <div className="form-group mb-3">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      onChange={(e) => setEmail(e.target.value.trim())}
                    />
                  </div>
                )}
                {step === 2 && (
                  <div className="form-group mb-3">
                    <label>Enter the 6-digit OTP code here</label>
                    <OTPInput onChange={onChangeOTP} />
                  </div>
                )}
                <div className="d-flex justify-content-center">
                  {loading && <div className="loader" />}
                  {step === 1
                    ? !loading && (
                        <button
                          type="button"
                          className={`btn btn-primary`}
                          onClick={handleLogin}
                        >
                          Send OTP
                        </button>
                      )
                    : !loading && (
                        <button
                          type="button"
                          className={`btn btn-success`}
                          onClick={handleVerifyOTP}
                        >
                          Verify OTP
                        </button>
                      )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Login;
